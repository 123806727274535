/* Lexica.css */

.lexica-page {
  font-family: Arial, sans-serif;
  font-size: 24px;
}

.lexica-header {

  color: white;
  padding: 50px;
  text-align: center;
}

.lexica-intro {
  padding: 20px;
 
}

.lexica-content {
  padding: 20px;
}

.lexica-footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}
